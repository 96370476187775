import * as Sentry from '@sentry/react'
import { supabaseClient } from 'api/supabase'
import perfectDayLogoUrl from 'assets/perfect-day-logo.png'
import { Button } from 'components/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { IsSignedIn } from 'types'

export const Age = () => {
  const { isSignedIn } = useLoaderData() as IsSignedIn

  const [isUserSignedIn, setIsUserSignedIn] = useState<boolean>(isSignedIn)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSignOut = async () => {
    const { error } = await supabaseClient.auth.signOut()

    if (error) {
      Sentry.captureException(error)
    } else {
      setIsUserSignedIn(false)
    }
  }

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-6">
        <header className="flex items-center justify-between">
          <img src={perfectDayLogoUrl} alt="perfect day logo" className="h-12 w-10" />

          {isUserSignedIn ? (
            <button className="font-bold" onClick={handleSignOut}>
              {t('drivers.logout')}
            </button>
          ) : (
            <Link className="font-bold" to={RoutePaths.Login}>
              {t('drivers.login')}
            </Link>
          )}
        </header>

        <h1 className="shrink-0 text-2xl font-bold">{t('age.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-yellow"
          emoji=""
          onClick={() => navigate(RoutePaths.Gender)}
          text={'18-24'}
        />

        <Button
          color="pd-purple"
          emoji=""
          onClick={() => navigate(RoutePaths.Gender)}
          text={'25-34'}
        />

        <Button
          color="pd-peach"
          emoji=""
          onClick={() => navigate(RoutePaths.Gender)}
          text={'35-44'}
        />

        <Button
          color="pd-green"
          emoji=""
          onClick={() => navigate(RoutePaths.Gender)}
          text={'45+'}
        />
      </div>
    </div>
  )
}
