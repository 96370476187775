import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const DriversOnb1 = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-6">
      

        <h1 className="shrink-0 text-2xl font-bold">{t('drivers.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-yellow"
          emoji="🎯"
          onClick={() => navigate(RoutePaths.Building)}
          text={t('drivers.driver_1')}
        />

        <Button
          color="pd-purple"
          emoji="💪"
          onClick={() => navigate(RoutePaths.Building)}
          text={t('drivers.driver_2')}
        />

        <Button
          color="pd-peach"
          emoji="💫"
          onClick={() => navigate(RoutePaths.Building)}
          text={t('drivers.driver_3')}
        />

        <Button
          color="pd-green"
          emoji="💚"
          onClick={() => navigate(RoutePaths.Building)}
          text={t('drivers.driver_4')}
        />
      </div>
    </div>
  )
}
