import appleTrust from 'assets/appleTrust.png'
import googleTrust from 'assets/googleTrust.png'
import { Accordion } from 'components/Accordion'
import { CTAButton } from 'components/CTAButton'
import { CheckIcon } from 'components/CheckIcon'
import { Modal } from 'components/Modal'
import { formatPrice } from 'helpers'
import getCurrencyAndPriceInCents from 'helpers/getCurrencyAndPriceInCents'
import getIntroPriceInCents from 'helpers/getIntroPriceInCents'
import useIntroPriceCoupon from 'hooks/useIntroPriceCoupon'
import { usePrices } from 'hooks/usePrices'
import { useUserLocale } from 'hooks/useUserLocale'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import MonthlyPlan from './MonthlyPlan'
import YearlyPlan from './YearlyPlan'
import { getUserCurrency } from 'helpers/sessionStorage'

export const Plans = () => {
  const [planModalOpen, setPlanModalOpen] = useState(false)

  const introPriceCoupon = useIntroPriceCoupon()
  const locale = useUserLocale()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { monthlyPrice, yearlyPrice, isLoading } = usePrices()
  
  const [yearlyPriceCurrency, setYearlyPriceCurrency] = useState<string>('')
  const [yearlyIntroPriceInCents, setYearlyIntroPriceInCents] = useState<number | null>(null)
  const [userCurrency, setUserCurrency] = useState<string>('')
  const request = getUserCurrency()

  useEffect(() => {
    request.then((currency) => {
      const { currency: newYearlyPriceCurrency, priceInCents: newYearlyPriceInCents } =
        getCurrencyAndPriceInCents({
          stripePrice: yearlyPrice,
          userCurrency: currency,
        })

      const newYearlyIntroPriceInCents = getIntroPriceInCents({
        introPriceCoupon,
        priceInCents: newYearlyPriceInCents,
      })

      // Store the results in states
      setUserCurrency(currency)
      setYearlyPriceCurrency(newYearlyPriceCurrency)
      setYearlyIntroPriceInCents(newYearlyIntroPriceInCents ?? null)
    })
  }, [userCurrency, yearlyPrice, introPriceCoupon, request])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPlanModalOpen(true)
    }, 30000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <div className="min-h-dvh animate-change-bg from-[#F5E1EF] to-[#DFF8FC]">
        <div className="flex min-h-dvh flex-col bg-gradient-to-b from-white to-transparent pt-3">
          <div className="container mx-auto flex max-w-5xl grow flex-col justify-between px-10 py-10">
            <div className="space-y-3">
              <h1 className="text-center text-2xl font-bold">{t('plans.headline')}</h1>

              <h2 className="text-md text-center font-semibold text-[#BBBFCB]">
                {t('plans.subheadline')}
              </h2>
            </div>

            <div className="mt-9 grid grid-cols-1 items-end gap-9 md:grid-cols-2">
              <YearlyPlan
                introPriceCoupon={introPriceCoupon}
                isLoading={isLoading}
                locale={locale}
                userCurrency={userCurrency}
                yearlyPrice={yearlyPrice}
              />

              <MonthlyPlan
                introPriceCoupon={introPriceCoupon}
                isLoading={isLoading}
                locale={locale}
                monthlyPrice={monthlyPrice}
                userCurrency={userCurrency}
              />
            </div>

            <div className="mt-12 space-y-11">
              {[1, 2, 3, 4].map((index) => (
                <div key={`benefit-${index}`} className="flex space-x-4">
                  <CheckIcon className="shrink-0" />

                  <div>
                    <p className="font-bold">{t(`plans.benefitHeader_${index}`)}</p>

                    <p>{t(`plans.benefitText_${index}`)}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-12 space-y-4 text-center">
              <h2 className="text-2xl font-bold">{t('plans.trust_element.headline')}</h2>
              <div className="flex justify-center space-x-4">
                <img src={appleTrust} alt="Apple Logo" className="h-auto w-40" />
                <img src={googleTrust} alt="Google Logo" className="h-auto w-40" />
              </div>
              <p>{t('plans.trust_element.andHas')}</p>
              <p className="text-xl font-bold">{t('plans.trust_element.userAmount')}</p>
              <p className="text-xl font-bold">⭐️⭐️⭐️⭐️⭐️</p>
            </div>

            <div className="mt-12 space-y-4">
              <h2 className="text-center text-2xl font-bold">{t('plans.faq.headline')}</h2>
              <Accordion title={t('plans.faq.firstHeadline')}>
                <p>{t('plans.faq.firstDescription')}</p>
              </Accordion>
              <Accordion title={t('plans.faq.secondHeadline')}>
                <p>{t('plans.faq.secondDescription')}</p>
              </Accordion>
              <Accordion title={t('plans.faq.thirdHeadline')}>
                <p>{t('plans.faq.thirdDescription')}</p>
              </Accordion>
            </div>

            <div className="mt-12 space-y-4">
              <CTAButton
                text={t('plans.cta')}
                onClick={() =>
                  navigate(RoutePaths.Checkout, {
                    state: {
                      couponId: introPriceCoupon?.id,
                      currency: yearlyPriceCurrency,
                      duration: 12,
                      priceId: yearlyPrice.id,
                    },
                  })
                }
              />

              <p className="text-center font-bold">{t('plans.cancel')}</p>
            </div>
          </div>
        </div>
      </div>

      {yearlyIntroPriceInCents && planModalOpen && (
        <Modal open={planModalOpen} setOpen={setPlanModalOpen}>
          <div className="text-center">
            <h1 className="mb-5 text-2xl font-bold">{t('plans.modal.headline')}</h1>

            <p className="text-4xl font-bold">12</p>

            <p className="text-lg uppercase">{t('plans.months')}</p>

            <p className="mb-6 mt-4 text-2xl">
              {formatPrice({
                currency: yearlyPriceCurrency,
                locale,
                priceInCents: yearlyIntroPriceInCents,
              })}
            </p>

            <CTAButton
              text={t('plans.modal.cta')}
              onClick={() =>
                navigate(RoutePaths.Checkout, {
                  state: {
                    couponId: introPriceCoupon?.id,
                    currency: yearlyPriceCurrency,
                    duration: 12,
                    priceId: yearlyPrice.id,
                  },
                })
              }
            />

            <p className="-mb-6 mt-4 px-4 font-bold">{t('plans.guarantee')}</p>
          </div>
        </Modal>
      )}
    </>
  )
}
