import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const TackleChallenge = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('tackleTasks.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-peach"
          emoji="🌅"
          onClick={() => navigate(RoutePaths.CleanTime)}
          text={t('tackleTasks.morning')}
        />

        <Button
          color="pd-blue"
          emoji="☀️"
          onClick={() => navigate(RoutePaths.CleanTime)}
          text={t('tackleTasks.afternoon')}
        />

        <Button
          color="pd-peach"
          emoji="🌇"
          onClick={() => navigate(RoutePaths.CleanTime)}
          text={t('tackleTasks.evening')}
        />

        <Button
          color="pd-peach"
          emoji="📆"
          onClick={() => navigate(RoutePaths.CleanTime)}
          text={t('tackleTasks.weekend')}
        />

        <Button
          color="pd-purple"
          emoji="🔁"
          onClick={() => navigate(RoutePaths.CleanTime)}
          text={t('tackleTasks.varies')}
        />
      </div>
    </div>
  )
}
