import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Route,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { Layout } from 'components/Layout'
import { RoutePaths } from 'routes'

import { getUserId } from 'api/supabase'
import { getIsPremiumEntitled } from 'helpers/subscriptions'
import { BuildingPlan } from 'pages/BuildingPlan'
import { Checkout } from 'pages/Checkout'
import { Desire } from 'pages/Desire'
import { Distractions } from 'pages/Distractions'
import { Drivers } from 'pages/Drivers'
import { Email } from 'pages/Email'
import { Feedback } from 'pages/Feedback'
import { FeedbackFeeling } from 'pages/FeedbackFeeling'
import { Feeling } from 'pages/Feeling'
import { ForgotPassword } from 'pages/ForgotPassword'
import { Goals } from 'pages/Goals'
import { Habits } from 'pages/Habits'
import { LastMinute } from 'pages/LastMinute'
import { Login } from 'pages/Login'
import { PartOfDay } from 'pages/PartOfDay'
import { Password } from 'pages/Password'
import PaymentConfirmation from 'pages/PaymentConfirmation'
import { Plans } from 'pages/Plans'
import { Ready } from 'pages/Ready'
import { ResetPassword } from 'pages/ResetPassword'
import { Review } from 'pages/Review'
import { StatementAnxious } from 'pages/StatementAnxious'
import { StatementRegrets } from 'pages/StatementRegrets'
import { ThankYou } from 'pages/ThankYou'
import { TimeOfDay } from 'pages/TimeOfDay'
import { DriversFlagValueLoaderData, IsPremiumEntitledAndIsSignedIn, IsSignedIn } from 'types'
import { ProtectedRoute } from './ProtectedRoute'
import { RedirectRoute } from './RedirectRoute'
import RefundGooglePage from 'pages/helpCenter/RefundGooglePage'
import CancelationPage from 'pages/helpCenter/CancelationPage'
import RefundPage from 'pages/helpCenter/RefundPage'
import RefundApplePage from 'pages/helpCenter/RefundApplePage'
import RefundCompletedPage from 'pages/helpCenter/RefundCompletedPage'
import CancelationGooglePage from 'pages/helpCenter/CancelationGooglePage'
import CancelationCompletedPage from 'pages/helpCenter/CancelationCompletedPage'
import CancelationApplePage from 'pages/helpCenter/CancelationApplePage'
import HelpCenterPage from 'pages/helpCenter/HelpCenterPage'
import { Age } from 'pages/onboarding1/age'
import { Gender } from 'pages/onboarding1/gender'
import { Glad } from 'pages/onboarding1/glad'
import { Sleep } from 'pages/onboarding1/sleep'
import { GetUp } from 'pages/onboarding1/getUp'
import { Energy } from 'pages/onboarding1/energy'
import { Lifestyle } from 'pages/onboarding1/lifestyle'
import { DriversOnb1 } from 'pages/onboarding1/drivers'
import { GoalsOnb1 } from 'pages/onboarding1/goals'
import { Motivation } from 'pages/onboarding1/motivation'
import { DistractionsOnb1 } from 'pages/onboarding1/distractions'
import { Procrastrination } from 'pages/onboarding1/procrastination'
import { Build } from 'pages/onboarding1/build'
import { DesireOnb1 } from 'pages/onboarding1/desire'
import { StatementAnxiousOnb1 } from 'pages/onboarding1/statementAnxious'
import { StatementNotEnoughTimeOnb1 } from 'pages/onboarding1/statementNotEnoughTime'
import { StatementRegretsOnb1 } from 'pages/onboarding1/statementRegret'
import { StatementConcentrationOnb1 } from 'pages/onboarding1/statementConcentration'
import { CleanSpace } from 'pages/onboarding2/cleanSpace'
import { CleaningFrequency } from 'pages/onboarding2/cleanFrequency'
import { BiggestChallenge } from 'pages/onboarding2/biggestChallenge'
import { TailorPlan } from 'pages/onboarding2/tailorPlan'
import { FeelingAfterClean } from 'pages/onboarding2/feelAfterClean'
import { FeelReachGoal } from 'pages/onboarding2/feelReachGoal'
import { LivingSpace } from 'pages/onboarding2/livingSpace'
import { FocusFirst } from 'pages/onboarding2/focusFirst'
import { PrimaryGoal } from 'pages/onboarding2/primaryGoal'
import { TackleChallenge } from 'pages/onboarding2/tackleChallenge'
import { CleanTime } from 'pages/onboarding2/cleanTime'
import { MinuteCounts } from 'pages/onboarding2/minuteCounts'
import { HarmoniousSpace } from 'pages/onboarding2/harmoniousSpace'
import { ReadyOnb1 } from 'pages/onboarding1/ready'
import { FeedbackOnb1 } from 'pages/onboarding1/feedback'
import getFlagValueLoader from 'loaders/getFlagValueLoader'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
})

async function loadIsSignedIn(): Promise<IsSignedIn> {
  const userId = await getUserId()

  return { isSignedIn: !!userId }
}

async function loadDriver(): Promise<DriversFlagValueLoaderData> {
  const userId = await getUserId()
  const flagValue = await getFlagValueLoader()

  return { isSignedIn: !!userId, flagValue }
}

async function loadIsPremiumEntitledAndIsSignedIn(): Promise<IsPremiumEntitledAndIsSignedIn> {
  const userId = await getUserId()
  const isPremiumEntitled = userId ? await getIsPremiumEntitled() : false

  return { isPremiumEntitled, isSignedIn: !!userId }
}

export const router = () => {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  return sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={RoutePaths.Root} element={<Layout />}>
          <Route path={RoutePaths.ForgotPassword} element={<ForgotPassword />} />
          <Route path={RoutePaths.Login} element={<Login />} />
          <Route path={RoutePaths.Drivers} element={<Drivers />} loader={loadDriver} />
          <Route path={RoutePaths.Goals} element={<Goals />} />
          <Route path={RoutePaths.Feedback} element={<Feedback />} />
          <Route path={RoutePaths.LastMinute} element={<LastMinute />} />
          <Route path={RoutePaths.Distractions} element={<Distractions />} />
          <Route path={RoutePaths.PartOfDay} element={<PartOfDay />} />
          <Route path={RoutePaths.Review} element={<Review />} />
          <Route path={RoutePaths.TimeOfDay} element={<TimeOfDay />} />
          <Route path={RoutePaths.Desire} element={<Desire />} />
          <Route path={RoutePaths.Feeling} element={<Feeling />} />
          <Route path={RoutePaths.FeedbackFeeling} element={<FeedbackFeeling />} />
          <Route path={RoutePaths.StatementAnxious} element={<StatementAnxious />} />
          <Route path={RoutePaths.StatementRegrets} element={<StatementRegrets />} />
          <Route path={RoutePaths.Habits} element={<Habits />} />
          <Route path={RoutePaths.Ready} element={<Ready />} />
          <Route path={RoutePaths.Password} element={<Password />} />
          <Route path={RoutePaths.Email} element={<Email />} />

          <Route
            path={RoutePaths.Root}
            element={<RedirectRoute />}
            loader={loadIsPremiumEntitledAndIsSignedIn}
          /> 
          {/* <Route path={RoutePaths.HelpCenter} element={<HelpCenterPage />} /> */}
         
          <Route element={<ProtectedRoute />} loader={loadIsSignedIn}>
            <Route path={RoutePaths.BuildingPlan} element={<BuildingPlan />} />
            <Route path={RoutePaths.Checkout} element={<Checkout />} />
            <Route path={RoutePaths.PaymentConfirmation} element={<PaymentConfirmation />} />
            <Route path={RoutePaths.ResetPassword} element={<ResetPassword />} />
            <Route path={RoutePaths.ThankYou} element={<ThankYou />} />
          </Route>

          <Route path={RoutePaths.HelpCenter} element={<HelpCenterPage />} />
          <Route path={RoutePaths.Cancelation} element={<CancelationPage />} />
          <Route path={RoutePaths.CancelationApple} element={<CancelationApplePage />} />
          <Route path={RoutePaths.CancelationGoogle} element={<CancelationGooglePage />} />
          <Route path={RoutePaths.CancelationCompleted} element={<CancelationCompletedPage />} />

          <Route path={RoutePaths.Refund} element={<RefundPage />} />
          <Route path={RoutePaths.RefundApple} element={<RefundApplePage />} />
          <Route path={RoutePaths.RefundGoogle} element={<RefundGooglePage />} />
          <Route path={RoutePaths.RefundCompleted} element={<RefundCompletedPage />} />
       
          {/* Onboarding1 */}
          <Route path={RoutePaths.Age} element={<Age />} loader={loadIsSignedIn} />
          <Route path={RoutePaths.Gender} element={<Gender />} />
          <Route path={RoutePaths.Glad} element={<Glad />} />
          <Route path={RoutePaths.Sleep} element={<Sleep />} />
          <Route path={RoutePaths.GetUp} element={<GetUp />} />
          <Route path={RoutePaths.Energy} element={<Energy />} />
          <Route path={RoutePaths.Lifestyle} element={<Lifestyle />} />
          <Route path={RoutePaths.GoalsOnb1} element={<GoalsOnb1 />} />
          <Route path={RoutePaths.Motivation} element={<Motivation />} />
          <Route path={RoutePaths.DistractionsOnb1} element={<DistractionsOnb1 />} />
          <Route path={RoutePaths.Procrastination} element={<Procrastrination />} />
          <Route path={RoutePaths.DriversOnb1} element={<DriversOnb1 />} />
          <Route path={RoutePaths.Building} element={<Build />} />
          <Route path={RoutePaths.DesireOnb1} element={<DesireOnb1 />} />
          <Route path={RoutePaths.StatementAnxiousOnb1} element={<StatementAnxiousOnb1 />} />
          <Route path={RoutePaths.StatementNotEnoughTimeOnb1} element={<StatementNotEnoughTimeOnb1 />} />
          <Route path={RoutePaths.StatementConcentrationOnb1} element={<StatementConcentrationOnb1 />} />
          <Route path={RoutePaths.StatementRegretnOnb1} element={<StatementRegretsOnb1 />} />
          <Route path={RoutePaths.ReadyOnb1} element={<ReadyOnb1 />} />
          <Route path={RoutePaths.FeedbackOnb1} element={<FeedbackOnb1 />} />

          {/* Onboarding2 */}
          <Route path={RoutePaths.CleanSpace} element={<CleanSpace />} loader={loadIsSignedIn} />
          <Route path={RoutePaths.CleaningFrequency} element={<CleaningFrequency />} />
          <Route path={RoutePaths.BiggestChallenge} element={<BiggestChallenge />} />
          <Route path={RoutePaths.TailorPlan} element={<TailorPlan />} />
          <Route path={RoutePaths.FeelingAfterCleaning} element={<FeelingAfterClean />} />
          <Route path={RoutePaths.FeelReachGoal} element={<FeelReachGoal />} />
          <Route path={RoutePaths.LivingSpace} element={<LivingSpace />} />
          <Route path={RoutePaths.FocusFirst} element={<FocusFirst />} />
          <Route path={RoutePaths.PrimaryGoal} element={<PrimaryGoal />} />
          <Route path={RoutePaths.TackleChallenge} element={<TackleChallenge />} />
          <Route path={RoutePaths.CleanTime} element={<CleanTime />} />
          <Route path={RoutePaths.MinuteCounts} element={<MinuteCounts />} />
          <Route path={RoutePaths.HarmoniousSpace} element={<HarmoniousSpace />} />
        </Route>,
     
        ,{/* NOTE: The plans page needs its custom layout */}
        <Route element={<ProtectedRoute />} loader={loadIsSignedIn}>
          <Route path={RoutePaths.Plans} element={<Plans />} />,
        </Route>
      </>,
    ),
  )
}
