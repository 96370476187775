import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const Gender = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('gender.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-orange"
          emoji="👩"
          onClick={() => navigate(RoutePaths.Glad)}
          text={t('gender.female')}
        />

        <Button
          color="pd-yellow"
          emoji="👱‍♂️"
          onClick={() => navigate(RoutePaths.Glad)}
          text={t('gender.male')}
        />

        <Button
          color="pd-peach"
          emoji="🧑"
          onClick={() => navigate(RoutePaths.Glad)}
          text={t('gender.other')}
        />
      </div>
    </div>
  )
}
