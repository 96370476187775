import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutWhite from "../../components/LayoutWhite";
import { RoutePaths } from "routes";
import { Button } from "@headlessui/react";

export default function HelpCenterPage() {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const className =
    "w-full rounded-2xl border-2 border-black inline-block p-4 text-center hover:opacity-50";

  return (
    <LayoutWhite>
      <div className="shrink-0">
        <h1 className="mb-11 font-bold">{t("pages.helpCenter.heading")}</h1>
      </div>

      <div className="flex grow flex-col justify-center space-y-5">
        <Link className={className} to="mailto:support@perfectday.ai">
          {t("pages.helpCenter.generalQuestionOrSuggestion")}
        </Link>

        <Button 
          className={className}
          onClick={() => navigate(RoutePaths.Cancelation)}
        >
          {t("pages.helpCenter.cancelSubscription")}
        </Button> 

        <Button 
          className={className}
          onClick={() => navigate(RoutePaths.Refund)}
        >
          {t("pages.helpCenter.requestRefund")}
        </Button>          
      </div>
    </LayoutWhite>
  );
}
