import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { RoutePaths } from 'routes'

export const FeelingAfterClean = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [, setFeeling] = useLocalStorage('feeling-onb2', '')


  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('feelingAfterCleaning.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-blue"
          emoji="☺️"
          onClick={() => {
            setFeeling(t('feelingAfterCleaning.response_1'));
            navigate(RoutePaths.FeelReachGoal);
          }}
          text={t('feelingAfterCleaning.response_1')}
        />

        <Button
          color="pd-peach"
          emoji="⚡"
          onClick={() => {
            setFeeling(t('feelingAfterCleaning.response_2'));
            navigate(RoutePaths.FeelReachGoal);
          }}
          text={t('feelingAfterCleaning.response_2')}
        />

        <Button
          color="pd-yellow"
          emoji="🏅"
          onClick={() => {
            setFeeling(t('feelingAfterCleaning.response_3'));
            navigate(RoutePaths.FeelReachGoal);
          }}
          text={t('feelingAfterCleaning.response_3')}
        />

        <Button
          color="pd-green"
          emoji="😌"
          onClick={() => {
            setFeeling(t('feelingAfterCleaning.response_4'));
            navigate(RoutePaths.FeelReachGoal);
          }}
          text={t('feelingAfterCleaning.response_4')}
        />
      </div>
    </div>
  )
}
