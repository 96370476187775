import clsx from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick: () => void
  disabled: boolean
  className?: string
}

export const LargeContinueButton: React.FC<Props> = ({ onClick, disabled, className }) => {
  const { t } = useTranslation()

  return (
    <button
      type="button"
      disabled={disabled}
      className={clsx(
        'inline-flex w-full justify-center rounded-full bg-[#222222] px-3 py-6 text-xl font-bold text-white shadow-[0_25px_30px_-10px_rgba(38,57,77,0.6)] focus-visible:outline-none',
        className,
        disabled ? 'opacity-60' : 'opacity-100',
      )}
      onClick={onClick}
    >
      {t('common.continue')}
    </button>
  )
}