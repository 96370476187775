import { getUser } from "helpers/supabase";
import { getGeolocation } from "./getGeolocation";

const CITY_KEY = "city";
const COUNTRY_CODE_KEY = "countrycode";
const CURRENCY_KEY = "currency";
const EMAIL_KEY = "email";
const ID_KEY = "id";
const ZIP_CODE_KEY = "zipcode";

export async function getUserCity(): Promise<string> {
  const cityFromSessionStorage = window.sessionStorage.getItem(CITY_KEY);

  if (cityFromSessionStorage) return cityFromSessionStorage;

  const geolocation = await getAndStoreGeolocation();

  return geolocation?.city;
}

export async function getUserCountryCode(): Promise<string> {
  const cityFromSessionStorage =
    window.sessionStorage.getItem(COUNTRY_CODE_KEY);

  if (cityFromSessionStorage) return cityFromSessionStorage;

  const geolocation = await getAndStoreGeolocation();

  return geolocation?.country;
}

export async function getUserCurrency(): Promise<string> {
  const currencyFromSessionStorage =
    window.sessionStorage.getItem(CURRENCY_KEY);

  if (currencyFromSessionStorage) return currencyFromSessionStorage;

  const geolocation = await getAndStoreGeolocation();

  return geolocation?.currency || "eur";
}

export async function getUserEmail(): Promise<string | undefined> {
  const emailFromSessionStorage = window.sessionStorage.getItem(EMAIL_KEY);

  if (emailFromSessionStorage) return emailFromSessionStorage;

  const user = await getAndStoreUser();

  return user?.email;
}

export async function getUserId(): Promise<string | undefined> {
  const idFromSessionStorage = window.sessionStorage.getItem(ID_KEY);

  if (idFromSessionStorage) return idFromSessionStorage;

  const user = await getAndStoreUser();

  return user?.id;
}

export async function getUserZipCode(): Promise<string> {
  const currencyFromSessionStorage =
    window.sessionStorage.getItem(ZIP_CODE_KEY);

  if (currencyFromSessionStorage) return currencyFromSessionStorage;

  const geolocation = await getAndStoreGeolocation();

  return geolocation?.zipcode;
}

async function getAndStoreGeolocation() {
  const geolocation = await getGeolocation();

  window.sessionStorage.setItem(CITY_KEY, geolocation?.city);
  window.sessionStorage.setItem(COUNTRY_CODE_KEY, geolocation?.country);
  window.sessionStorage.setItem(CURRENCY_KEY, geolocation?.currency);
  window.sessionStorage.setItem(ZIP_CODE_KEY, geolocation?.zipcode);

  return geolocation;
}

async function getAndStoreUser() {
  const { data } = await getUser();

  const user = data.user;

  window.sessionStorage.setItem(EMAIL_KEY, user?.email ?? "");
  window.sessionStorage.setItem(ID_KEY, user?.id ?? "");

  return user;
}