import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { RoutePaths } from 'routes'

export const CleanTime = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [, setTask] = useLocalStorage('task-onb2', '')

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('cleanTime.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-peach"
          emoji="⏱️"
          onClick={() => {
            setTask(t('cleanTime.cleanTime_1'));
            navigate(RoutePaths.MinuteCounts);
          }}
          text={t('cleanTime.cleanTime_1')}
        />

        <Button
          color="pd-purple"
          emoji="🕕"
          onClick={() => {
            setTask(t('cleanTime.cleanTime_2'));
            navigate(RoutePaths.MinuteCounts);
          }}
          text={t('cleanTime.cleanTime_2')}
        />

        <Button
          color="pd-peach"
          emoji="🕐"
          onClick={() => {
            setTask(t('cleanTime.cleanTime_3'));
            navigate(RoutePaths.MinuteCounts);
          }}
          text={t('cleanTime.cleanTime_3')}
        />

        <Button
          color="pd-purple"
          emoji="⏲️"
          onClick={() => {
            setTask(t('cleanTime.cleanTime_4'));
            navigate(RoutePaths.MinuteCounts);
          }}
          text={t('cleanTime.cleanTime_4')}
        />
      </div>
    </div>
  )
}
