import { useQuery } from '@tanstack/react-query'
import { Modal } from 'components/Modal'
import { formatDateString } from 'helpers'
import { getRevenueCatSubscription } from 'helpers/subscriptions'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import TableRow from './TableRow'

type SubscriptionInfoModalProps = {
  setOpen: (open: boolean) => void
}

export default function SubscriptionInfoModal({ setOpen }: SubscriptionInfoModalProps) {
  const { t } = useTranslation()

  const { isPending, data: revenueCatSubscription } = useQuery({
    queryKey: ['revenueCatSubscription'],
    queryFn: getRevenueCatSubscription,
  })

  const isCanceled = revenueCatSubscription?.unsubscribe_detected_at

  const type = useMemo(() => {
    const store = revenueCatSubscription?.store

    switch (store) {
    case null:
    case undefined:
      return t('thankYou.subscriptionInfoModal.unknown').toUpperCase()
    case 'stripe':
      return 'WEB'
    default:
      return store.split('_').join(' ').toUpperCase()
    }
  }, [revenueCatSubscription?.store, t])

  return (
    <Modal open setOpen={setOpen}>
      {isPending ? (
        <ClipLoader />
      ) : (
        <div className="space-y-10">
          <p>
            {isCanceled ? (
              <Trans i18nKey="thankYou.subscriptionInfoModal.subscriptionCanceled" />
            ) : (
              <Trans i18nKey="thankYou.subscriptionInfoModal.subscriptionActive" />
            )}
          </p>

          <table className="w-full">
            <tbody>
              <TableRow
                label={t('thankYou.subscriptionInfoModal.status')}
                value={
                  isCanceled
                    ? t('thankYou.subscriptionInfoModal.canceled')
                    : t('thankYou.subscriptionInfoModal.active')
                }
              />

              <TableRow
                label={t('thankYou.subscriptionInfoModal.begins')}
                value={formatDateString(revenueCatSubscription?.purchase_date)}
              />

              <TableRow
                label={t('thankYou.subscriptionInfoModal.ends')}
                value={formatDateString(revenueCatSubscription?.expires_date)}
              />

              {type && <TableRow label={t('thankYou.subscriptionInfoModal.type')} value={type} />}
            </tbody>
          </table>

          <div className="text-center">
            <Link className="font-bold underline" to="/help">
              {t('thankYou.subscriptionInfoModal.needHelpContactUs')}
            </Link>
          </div>
        </div>
      )}
    </Modal>
  )
}
