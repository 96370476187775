import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const Sleep = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('sleep.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-orange"
          emoji="🥱"
          onClick={() => navigate(RoutePaths.GetUp)}
          text={t('sleep.lessThan6')}
        />

        <Button
          color="pd-yellow"
          emoji="😊"
          onClick={() => navigate(RoutePaths.GetUp)}
          text={t('sleep.between6And8')}
        />

        <Button
          color="pd-peach"
          emoji="😀"
          onClick={() => navigate(RoutePaths.GetUp)}
          text={t('sleep.between8And10')}
        />

        <Button
          color="pd-blue"
          emoji="😄"
          onClick={() => navigate(RoutePaths.GetUp)}
          text={t('sleep.moreThan10')}
        />
      </div>
    </div>
  )
}
