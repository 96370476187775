import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { LargeContinueButton } from 'components/LargeContinueButton'
import heartIcon from 'assets/heartIcon.png'
import { useLocalStorage } from 'react-use'

export const MinuteCounts = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [task] = useLocalStorage('task-onb2', '')

  const handleContinue = () => {
    navigate(RoutePaths.HarmoniousSpace)
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-0 m-auto pb-24">
      <div className="flex flex-col items-center space-y-8">
        <img src={heartIcon} alt="Glad Icon" className="h-auto w-40 rounded-lg" />

        <h1
          className="text-center text-2xl font-bold"
          dangerouslySetInnerHTML={{
            __html: t('minuteCounts.headline', { task: task?.toLocaleLowerCase(), interpolation: { escapeValue: false } }),
          }}
        />
      </div>

      <div className="fixed bottom-8 left-0 right-0 mb-4 flex justify-center">
        <div className="w-full max-w-3xl px-8">
          <LargeContinueButton onClick={handleContinue} disabled={false} />
        </div>
      </div>
    </div>
  )
}
