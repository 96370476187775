import * as Sentry from "@sentry/react";
import { BackendNetAmount, StripePaymentMethodType, Geolocation } from "supabase_types";

const backendApiBaseUrl = "https://api.asanarebel.com/api/v1/";

export async function getGeolocation(): Promise<Geolocation> {
  const response = await fetch(backendApiBaseUrl + "geolocation");

  const geolocation = (await response.json()) as Geolocation;

  return geolocation;
}

export async function getNetAmount({
  countryCode,
  currency,
  grossAmount,
  paymentMethodType,
}: {
   countryCode: string;
   currency: string;
   grossAmount: number;
   paymentMethodType: StripePaymentMethodType;
 }): Promise<number | undefined> {
  try {
    const response = await fetch(backendApiBaseUrl + "net_amounts", {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        country_code: countryCode,
        currency,
        gross_amount: grossAmount,
        payment_method_type: paymentMethodType,
      }),
      method: "POST",
    });

    const netAmount = (await response.json()) as BackendNetAmount;

    return netAmount.net_amount;
  } catch (error) {
    Sentry.captureException(
      `Fetching net amount failed with countryCode=${countryCode}, currency=${currency}, grossAmount=${grossAmount}, paymentMethodType=${paymentMethodType}`,
    );

    return undefined;
  }
}