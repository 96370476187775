import { Confidence, visitorIdentity } from "@spotify-confidence/sdk";
import { trackConfidenceEvent } from "api/supabase";
import Cookies from 'js-cookie'

const confidence = Confidence.create({
  clientSecret: import.meta.env.VITE_CONFIDENCE_CLIENT_SECRET as string,
  environment: "client",
  region: "eu",
  timeout: 1000,
});

export interface GetFlagValueLoaderData {
  flagValue: {
    onboarding: string;
  };
}

export default async function getFlagValueLoader(): Promise<GetFlagValueLoaderData> {
  const visitorId = visitorIdentity()
  confidence.track(visitorId);
  
  const flagValue = await confidence.getFlag(
    "pd-web-onboarding",
    {
      onboarding: "123",
    },
  );

  console.log("flagValue", flagValue);

  trackConfidenceEvent({
    visitorId: Cookies.get("cnfdVisitorId") ?? "unknown",
    value: flagValue.onboarding,
  });

  return { flagValue };
}