export enum RoutePaths {
  Root = '/',
  Drivers = '/drivers',
  Goals = '/goals',
  Feedback = '/feedback',
  LastMinute = '/last-minute',
  Distractions = '/distractions',
  PartOfDay = '/part-of-day',
  Review = '/review',
  TimeOfDay = '/time-of-day',
  Desire = '/desire',
  Feeling = '/feeling',
  FeedbackFeeling = '/feedback-feeling',
  StatementAnxious = '/statement-anxious',
  StatementRegrets = '/statement-regrets',
  Habits = '/habits',
  Ready = '/ready',
  Password = '/password',
  Email = '/email',
  BuildingPlan = '/building-plan',
  Plans = '/plans',
  Checkout = '/checkout',
  PaymentConfirmation = '/payment-confirmation',
  ThankYou = '/thank-you',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Login = '/login',
  HelpCenter = '/help',
  Cancelation = '/help/cancelation',
  CancelationApple = '/help/cancelation/apple',
  CancelationGoogle = '/help/cancelation/google',
  CancelationCompleted = '/help/cancelation/completed',
  Refund = '/help/refund',
  RefundApple = '/help/refund/apple',
  RefundGoogle = '/help/refund/google',
  RefundCompleted = '/help/refund/completed',

  // Onboarding1
  Age = '/onboarding1/age',
  Gender = '/onboarding1/gender',
  Glad = '/onboarding1/glad',
  Sleep = '/onboarding1/sleep',
  GetUp = '/onboarding1/get-up',
  Energy = '/onboarding1/energy',
  Lifestyle = '/onboarding1/lifestyle',
  GoalsOnb1 = '/onboarding1/goals',
  Motivation = '/onboarding1/motivation',
  DistractionsOnb1 = '/onboarding1/distractions',
  Procrastination = '/onboarding1/procrastination',
  DriversOnb1 = '/onboarding1/drivers',
  Building = '/onboarding1/building',
  DesireOnb1 = '/onboarding1/desire',
  StatementAnxiousOnb1 = '/onboarding1/statement-anxious',
  StatementNotEnoughTimeOnb1 = '/onboarding1/statement-time',
  StatementConcentrationOnb1 = '/onboarding1/statement-concentration',
  StatementRegretnOnb1 = '/onboarding1/statement-regret',
  ReadyOnb1 = '/onboarding1/ready',
  FeedbackOnb1 = '/onboarding1/feedback',

  // Onboarding2
  CleanSpace = '/onboarding2/clean-space',
  CleaningFrequency = '/onboarding2/cleaning-frequency',
  BiggestChallenge = '/onboarding2/biggest-challenge',
  TailorPlan = '/onboarding2/tailor-plan',
  FeelingAfterCleaning = '/onboarding2/feeling-after-cleaning',
  FeelReachGoal = '/onboarding2/feel-reach-goal',
  LivingSpace = '/onboarding2/living-space',
  FocusFirst = '/onboarding2/focus-first',
  PrimaryGoal = '/onboarding2/primary-goal',
  TackleChallenge = '/onboarding2/tackle-challenge',
  CleanTime = '/onboarding2/clean-time',
  MinuteCounts = '/onboarding2/minute-counts',
  HarmoniousSpace = '/onboarding2/harmonious-space',

}
