import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const GetUp = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('getUp.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-orange"
          emoji="🕑"
          onClick={() => navigate(RoutePaths.Energy)}
          text={t('getUp.between0And10')}
        />

        <Button
          color="pd-yellow"
          emoji="🕓"
          onClick={() => navigate(RoutePaths.Energy)}
          text={t('getUp.between10And20')}
        />

        <Button
          color="pd-peach"
          emoji="🕕"
          onClick={() => navigate(RoutePaths.Energy)}
          text={t('getUp.between20And30')}
        />

        <Button
          color="pd-blue"
          emoji="🕘"
          onClick={() => navigate(RoutePaths.Energy)}
          text={t('getUp.moreThan30')}
        />
      </div>
    </div>
  )
}
